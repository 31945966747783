<template>
  <div id="ServiceTeam-list">
    <div class="team-back">
      <el-button size="small" type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-button
      size="small"
      type="primary"
      style="margin:0 0 8px 10px;"
      @click="$router.push('/serviceTeamList/add')"
    >服务团队</el-button>
    <el-table :data="teamData" style="width: 100%">
      <el-table-column align="center" prop="image" label="图片" width="180">
        <template slot-scope="scope">
          <img :src="scope.row.images" alt="团队图片" width="100" height="60" />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="name" label="姓名"></el-table-column>
      <el-table-column align="center" prop="position" label="职位"></el-table-column>
      <el-table-column align="center" prop="brand" label="品牌"></el-table-column>
      <el-table-column align="center" prop="industry_labels" label="领域">
        <template slot-scope="scope">
          <el-tag
            size="medium"
            v-for="(item,i) in scope.row.industry_labels"
            :key="i"
          >{{item.title}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="rights" label="主要职责"></el-table-column>
      <el-table-column align="center" prop="operation" label="操作">
        <template slot-scope="scope">
          <el-button @click="editTeam(scope.row)" type="primary" size="mini">编辑</el-button>
          <el-button @click="delTeam(scope.row)" type="primary" size="mini" style="color:red">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <Page
      @currentPageChange="currentPageChange"
      :pageSize="pageData.per_page"
      :page="pageData.current_page"
      :total="pageData.total"
      style="position:absolute;bottom:10px;left:45%"
    ></Page>
  </div>
</template>
<script>
import Page from "@/components/PageComponents";
import { success } from "@/utils/notification";
export default {
  name: "ServiceTeam",
  components: {
    Page
  },
  data() {
    return {
      teamData: [], //表格数据
      page: 1,
      pageData: {
        //分页数据
        per_page: 0,
        current_page: 0,
        total: 0
      }
    };
  },
  created() {
    this.getServiceList();
  },
  methods: {
    //分页切换
    currentPageChange(p) {
      this.page = p;
      this.getServiceList();
    },
    //编辑服务团队
    editTeam(row) {
      this.$router.push(`/serviceTeamList/edit/${row.id}`);
    },
    //删除服务团队
    delTeam(row) {
      this.$confirm(" 是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$http
            .delete(this.URL.adminUrl.delTeam + row.id, {})
            .then(res => {
              this.getServiceList();
              console.log(res);
            });
          success("删除成功");
        })
        .catch(() => {});
    },

    //获取团队服务列表
    getServiceList() {
      let id = sessionStorage.organizationId;
      this.$http
        .get(this.URL.adminUrl.teamOrgan + id, {
          params: {
            page: this.page
          }
        })
        .then(res => {
          this.teamData = res.data.data.data;
          this.pageData = res.data.data.meta;
        });
    }
  }
};
</script>
<style lang="less">
#ServiceTeam-list {
  .team-back {
    margin: 20px 10px;
  }
}
</style>